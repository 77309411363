import React from "react";

const IconArrowNextRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      fill="none"
      viewBox="0 0 10 14"
    >
      <path
        fill="#fff"
        d="M2.325 0L.675 1.65 6.024 7l-5.35 5.35L2.326 14l7-7-7-7z"
      ></path>
    </svg>
  );
};

export default IconArrowNextRight;
