import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ModelContext = createContext();

function ModelContextProvider({ children }) {
  const location = useLocation();
  const [showModel, setShowModel] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const [searchQuery, setSearchQuery] = useState(urlParams.get("query") || "");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const hideModel = () => {
    setShowModel(!showModel);
  };

  const value = useMemo(
    () => ({
      handleSearch,
      searchQuery,
      showModel,
      hideModel,
    }),
    [handleSearch, searchQuery, showModel, hideModel]
  );

  return (
    <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
  );
}
ModelContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ModelContextProvider;

export function useModelContext() {
  return useContext(ModelContext);
}
