import {
  ADDALERTDATA,
  ADDDEVICEINFO,
  ADDUSERDATA,
  ADD_ACCESS_LIST,
  ADD_REDIRECT_URL,
  CHANGE_ACTIVE_SIDEBAR,
  LOGOUT,
  TICKETING_FOOTER_DISPLAY,
  TOGGLE_LOADER,
  TOGGLE_NAVBAR,
  UPDATE_DETAILS,
} from "../constants";

export const toggleNavbarAction = () => {
  return {
    type: TOGGLE_NAVBAR,
    value: null,
  };
};
export const addRedirectUrlAfterLoginAction = (data) => {
  return {
    type: ADD_REDIRECT_URL,
    value: data,
  };
};

export const toggleLoaderAction = (data) => {
  return {
    type: TOGGLE_LOADER,
    value: data,
  };
};

export const addUserDataToStoreAction = (data) => {
  return {
    type: ADDUSERDATA,
    value: data,
  };
};

export const addAlertDataToStoreAction = (data) => {
  return {
    type: ADDALERTDATA,
    value: data,
  };
};

export const addDeviceInfoToStoreAction = (data) => {
  return {
    type: ADDDEVICEINFO,
    value: data,
  };
};
export const updateCameraDetailsAction = (contact, index) => {
  return {
    type: UPDATE_DETAILS,
    contact,
    index,
  };
};
export const changeActiveSidebarAction = (data) => {
  return {
    type: CHANGE_ACTIVE_SIDEBAR,
    value: data,
  };
};

export const logoutAction = (data) => {
  return {
    type: LOGOUT,
    value: data,
  };
};

export const addAccessListAction = (data) => {
  return {
    type: ADD_ACCESS_LIST,
    value: data,
  };
};

export const toggleTicketingFooter = () => {
  return {
    type: TICKETING_FOOTER_DISPLAY,
  };
};
