import React from "react";

const IconWarningToast = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18C4.05 18 0 13.95 0 9C0 4.05 4.05 0 9 0C13.95 0 18 4.05 18 9C18 13.95 13.95 18 9 18ZM9.9 4.5H8.1V9.9H9.9V4.5ZM9.9 11.7H8.1V13.5H9.9V11.7Z"
        fill="#FFC107"
      />
    </svg>
  );
};

export default IconWarningToast;
