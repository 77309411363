import React from "react";
import HomeComponent from "../Components/HomeComponent";
import Layout from "../../Common/Containers/_layouts/Home";
import Helmet from "react-helmet";
import { checkWobotDomain, PAGE_TITLES } from "../../Services/checkWobotDomain";
import { home } from "../../Constants/MetaDetails";

const Home = () => {
  return (
    <Layout>
      <Helmet>
        <title>{checkWobotDomain(PAGE_TITLES.home)}</title>
        <meta name="description" content={home?.subtitle} />
      </Helmet>
      <HomeComponent />
    </Layout>
  );
};

export default Home;
