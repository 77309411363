import { toast } from "react-toastify";

import {
  IconErrorToast,
  IconSuccessToast,
  IconWarningToast,
} from "../Common/Components/IconsComponent/Index";
import Service from "./Service";

const showIcon = (type) => {
  switch (type) {
    case "info":
      return <IconSuccessToast />;
    case "error":
      return <IconErrorToast />;
    case "warning":
      return <IconWarningToast />;
    case "success":
      return <IconSuccessToast />;

    default:
      return <IconSuccessToast />;
  }
};
const upperCaseFirstLetter = (string) => {
  if (string && string.length && typeof string === `string`) {
    return `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;
  }
  return "";
};

const lowerCaseAllWordsExceptFirstLetters = (string) => {
  if (string && string.length && typeof string === `string`) {
    return string.replaceAll(
      /\S*/g,
      (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
    );
  }
  return "";
};

export const showAlert = (message, type = "info") => {
  toast.dismiss();
  if (Service.isOnline()) {
    toast.info(
      `${upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(message))}`,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        closeButton: true,
        closeOnClick: false,
        pauseOnHover: false,
        type: type,
        icon: showIcon(type),
        hideProgressBar: true,
        autoClose: 3000,
      }
    );
  } else {
    window.location.reload();
  }
};

// IconWarningToast,
//   IconSuccessToast,
//   IconInfoToast,
//   IconErrorToast,
