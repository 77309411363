export const home = {
  title: "Welcome",
  subtitle:
    "Onboard your existing security cameras and start generating operational insights in real time",
};

export const wocam = {
  cameras: {
    title: "Cameras",
    subtitle: "Onboard and manage your cameras here",
  },
  nvrs: {
    title: "NVRs",
    subtitle: "Add your NVRs to connect and manage multiple cameras at once",
  },
  playback: {
    title: "Playback",
    subtitle: "View & search all the recorded videos in one place",
  },
  liveView: {
    title: "Live View",
    subtitle: "View multiple live camera feeds together in one place",
  },
};

export const tasks = {
  tasks: {
    title: "Tasks",
    subtitle: "Automate tasks and view generated events",
  },
  myTasks: {
    title: "My Tasks",
    subtitle: "View and complete all the tasks assigned to you",
  },
};

export const tickets = {
  title: "Tickets",
  subtitle: "View all the tickets and alerts generated for tasks here",
};

export const events = {
  title: "Events",
  subtitle: "View all the events and detections generated here",
};

export const analytics = {
  tickets: {
    title: "Tickets",
    subtitle: "Analytics for all tickets raised on your account",
  },
  events: {
    title: "Events",
    subtitle: "Analytics for all events generated for your tasks",
  },
};

export const settings = {
  title: "Settings",
  users: {
    subtitle: "Invite your team to collaborate on tasks, playback and more",
  },
  advanced: {
    subtitle: "Manage your camera stream here",
  },
  regions: {
    subtitle:
      "Add the regions your business is located in (e.g. states or countries)",
  },
  cities: {
    subtitle: "Add the cities your business is located in",
  },
  locations: {
    subtitle: "Add and manage the areas your business is located in",
    locationDetails: {
      title: "Location Details",
      subtitle: "View and manage the location's details",
    },
  },
  schedule: {
    subtitle: "Manage schedules for running tasks and recordings",
  },
  shift: {
    subtitle: "Manage shifts for detailed shift-wise insights for tasks",
  },
  station: {
    subtitle:
      "Manage the drive-thru stations where cars pause or engage, such as Order and Pickup",
  },
  zone: {
    subtitle:
      "Manage the different zones where customers interact, like Order or POS area",
  },
  integrations: {
    subtitle: "Integrate with third-party services to get more out of Wobot AI",
  },
  company: {
    subtitle: "Manage your company related information here",
  },
  usageAndPlans: {
    subtitle: "Manage your plan usage and subscription details here",
  },
  woconnectDetails: {
    subtitle: "WoConnect settings",
  },
};

export const driveThru = {
  title: "DriveThru",
  subtitle: "A single window to track a car's DriveThru journey",
  location: {
    subtitle: "An overview on the performance of the location",
  },
  CarJourneys: {
    subtitle: "An overview on the performance of the location",
  },
};

export const walkIn = {
  title: "Customer Journey",
  subtitle: "Monitor your store customer’s analytics",
  location: {
    title: "Location Details",
    subtitle:
      "Track store analytics for the selected location. All values mentioned are averages unless specified otherwise.",
  },
  CustomerJourneys: {
    title: "Journey Details",
    subtitle: "An overview on the performance of the location",
  },
};
