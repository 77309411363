import React from "react";

const IconCorrect = ({ colour = "CED4DA", filled = false }) => {
  return filled ? (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#FFFFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11ZM14.2929 7.29289L9 12.5858L6.70711 10.2929L5.29289 11.7071L9 15.4142L15.7071 8.70711L14.2929 7.29289Z"
        fill={colour}
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.667969 7.99935C0.667969 12.0494 3.95121 15.3327 8.0013 15.3327C12.0514 15.3327 15.3346 12.0494 15.3346 7.99935C15.3346 3.94926 12.0514 0.666016 8.0013 0.666016C3.95121 0.666016 0.667969 3.94926 0.667969 7.99935ZM10.1965 5.52792L6.66794 9.05651L5.13935 7.52792L4.19654 8.47073L6.66794 10.9421L11.1393 6.47073L10.1965 5.52792Z"
        fill={colour}
      />
    </svg>
  );
};

export default IconCorrect;
