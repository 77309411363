import React, {useEffect, useRef, useState} from "react";
import {Tag} from "antd";

export default function Canvas({
                                 url,
                                 stationId,
                                 camera,
                                 boundingBox,
                                 config,
                                 roi,
                                 lineCrossingEntry,
                                 lineCrossingExit,
                                 showGrade,
                                 poi
                               }) {
  const [healthGrade, setHealthGrade] = useState();
  let image = new Image();
  let canvas = useRef(null);
  const canvasRef = useRef(null);
  let contextRef = useRef(null);

  const drawCircle = (boundingBox, coords, h, w) => {
    contextRef.current.beginPath();
    contextRef.current.font = "10px Verdana";
    contextRef.current.fillStyle = "#21fac8";
    contextRef.current.borderRadius = "50%";
    contextRef.current.fillRect(coords[0] * h, coords[1] * w, 5, 5);
  }

  const drawPoly = (coords, strokeColor, h, w) => {
    if (!coords || !coords.length) return;
    if (coords && coords.length && contextRef && contextRef.current) {
      contextRef.current.beginPath();
      for (let item = 0; item <= coords.length - 1; item++) {
        let lineone = coords[item][0] * h;
        let linetwo = coords[item][1] * w;
        contextRef.current.lineTo(lineone, linetwo);
      }
      contextRef.current.strokeStyle = strokeColor;
      contextRef.current.lineWidth = 2;
      contextRef.current.closePath();
      contextRef.current.stroke();
    }
  };

  const gradeInfo = [{
    color: "#029262", name: "Grade A", grade: "A", content: "no offline time",
  }, {
    color: "rgba(2, 146, 98, 0.75)", name: "Grade B", grade: "B", content: "< 60 minutes in last 30 days",
  }, {
    color: "#FFC107", name: "Grade C", grade: "C", content: "< 4 hours in last 30 days",
  }, {
    color: "rgba(220, 53, 69, 0.75)", name: "Grade D", grade: "D", content: "< 20 hours in last 30 days",
  }, {
    color: "rgba(220, 53, 69, 0.75)", name: "Grade E", grade: "E", content: "> 20 hours in last 30 days",
  },];

  const drawROI = (h, w, stationId, roi, lineCrossingEntry, lineCrossingExit, boundingBox, poi) => {
    if(config?.stations) Object.keys(config?.stations).forEach((i) => {
      Object.keys(config?.stations[i]).forEach((j) => {
        if (config?.stations[i][j].stationId === stationId && config?.stations[i][j]['camera_0'].cameraId === camera) {
          if (config?.stations[i][j]['camera_0']) {
            if (showGrade) {
              setHealthGrade(() => {
                const obj = gradeInfo.find((o) => {
                  if (o.name === config.stations[i][j]['camera_0'].healthGrade) return o;
                });
                return {...obj}
              })
            }
          }
        }
      })
    })
    if (boundingBox && boundingBox.length) {
      drawPoly(boundingBox, "#7FFF00", h, w);
    }
    if (roi && roi.length) {
      drawPoly(roi, "#3766E8FF", h, w);
    }
    if (lineCrossingExit && lineCrossingExit.length) {
      drawPoly(lineCrossingExit, "#da1234", h, w)
    }
    if (lineCrossingEntry && lineCrossingEntry.length) {
      drawPoly(lineCrossingEntry, "#fba700", h, w)
    }
    if (poi && poi.length) {
      drawCircle(poi, poi, h, w)
    }
  }

  const drawImage = async () => {
    canvas.current = canvasRef.current;
    if (canvas.current) {
      image.src = url;
      contextRef.current = canvas.current.getContext("2d");
      image.addEventListener("load", (e) => {
        if (canvas && canvas.current) setTimeout(() => {
          let height = 180;
          let width = 320;
          canvas.current.height = height;
          canvas.current.width = width;
          contextRef.current.drawImage(image, 0, 0, width, height);
          drawROI(width, height, stationId, roi, lineCrossingEntry, lineCrossingExit, boundingBox, poi);
        }, 100);
      });
    }
  };

  useEffect(() => {
    drawImage();
  }, []);

  return <>
    <canvas ref={canvasRef}/>
    {showGrade && <Tag color={healthGrade?.color} style={{
      position: "absolute", bottom: "74px", right: "0px"
    }}>{healthGrade?.grade}</Tag>}
  </>;
}
