import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { SIGNUP, SSOLOGIN } from "../../../Constants";
import Service from "../../../Services/Service";
import {
  PAGE_TITLES,
  checkWobotDomain,
} from "../../../Services/checkWobotDomain";
import getQueryVariable from "../../../Services/getQueryVariable";
import { validateEmpty } from "../../../Services/validation";
import IconViewHide from "../IconsComponent/IconViewHide";
import { IconView } from "../IconsComponent/Index";
import CenterBlock from "../Molecule/Block/CenterBlock";
import RotateBlock from "../Molecule/RotateBlock";
import "./style.scss";

function LoginComponent(props) {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  /**
   * Password Show/Hide
   */
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <RotateBlock showAuthBoxFw={false} showAuthBox>
      <Helmet>
        <title>{checkWobotDomain(PAGE_TITLES.login)}</title>
        <meta name="description" content="Log in to your Wobot account" />
      </Helmet>
      <CenterBlock
        showWobotIcon
        showAuthBottomLink={false}
        showAuthBottomNav={false}
        authBottomText="New here?"
        authBottomLink={SIGNUP}
        authBottomLinkText="Create an account"
        className="auth-login-layout auth-login"
      >
        <div className="auth-content">
          <h3 className="auth-title">Log in to your account</h3>
          <form
            className="auth-form-block"
            action=""
            onSubmit={(event) => {
              event.preventDefault();
              Service.login({
                isDemoAccount: false,
                username,
                password,
                history,
                next: history.location?.state?.from || null,
              });
            }}
          >
            <div className="form-group">
              <label htmlFor="inputAddress" className="form-label">
                Email
              </label>
              <input
                type="text"
                name="username"
                tabIndex={1}
                className={`form-control ${isEmailInvalid ? "error-red" : ""}`}
                placeholder="e.g. john@example.com"
                value={username}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  setUsername(value);
                  setIsEmailInvalid(validateEmpty(value));
                }}
              />
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between">
                <label htmlFor="inputAddress" className="form-label">
                  Password
                </label>
                {/*<span className="small">
                  <Link
                    to={{
                      pathname: "/forgot",
                      state: { email: `${username}` },
                    }}
                  >
                    Forgot Password?
                  </Link>
                </span>*/}
              </div>
              <div className="p-relative">
                <input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  minLength="8"
                  maxLength="20"
                  tabIndex={2}
                  className="form-control password-field mb-0"
                  placeholder="e.g. ••••••••"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
                <span className="pwd" onClick={togglePasswordVisiblity}>
                  {passwordShown ? <IconView /> : <IconViewHide />}
                </span>
              </div>
            </div>
            <button
              type="submit"
              tabIndex={3}
              className="btn btn-block btn-primary btn-xl mt-3"
            >
              {getQueryVariable("demo") === "true"
                ? "Log In to your demo account"
                : "Log In"}
            </button>
          </form>
        </div>
      </CenterBlock>
    </RotateBlock>
  );
}

export default LoginComponent;
