import React from "react";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
// import "./style.scss";
import Skeleton from "react-loading-skeleton";

const CameraGridSkeleton = () => {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div
          className={"list-inline-item w-100 d-flex justify-content-between"}
        >
          <Skeleton width={700} height={33} />
          <Skeleton width={75} height={33} />
        </div>

        <div className={"panel-body mt-60"}>
          <div className="skeleton-grid row mb-4">
            {Array(4)
              .fill()
              .map((e, index) => (
                <div className={"mb-2  col-xl-3 col-lg-4"} key={index}>
                  <Skeleton width={"100%"} height={300} />
                </div>
              ))}
          </div>
          <div className="skeleton-grid row mb-4">
            {Array(4)
              .fill()
              .map((e, index) => (
                <div className={"mb-2  col-xl-3 col-lg-4"} key={index}>
                  <Skeleton width={"100%"} height={300} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraGridSkeleton;
