import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {deviceDetect, osName} from "react-device-detect";
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {v4 as uuid} from "uuid";
import "./Main.scss";
import 'antd/dist/antd.css';
import {showAlert} from "./Services/showAlert";
// Route import
import {
    DRIVETHRU,
    FORGET,
    HOME,
    LOCATION,
    LOCATION_DETAILS,
    LOGIN,
    LOGOUT, MODELS,
    PAGE500,
    SUCCESS,
    VERIFY,
} from "./Constants";

// Import Redux Actions
import {
    addDeviceInfoToStoreAction,
    addUserDataToStoreAction,
    changeActiveSidebarAction,
    toggleLoaderAction,
} from "./Store/actions";

// Import Access
import {LoginRoutesComponent, PrivateRouteComponent,} from "./Common/Components/RouteHandlingComponents";

// Login & Forget
import Forget from "./Common/Containers/Forget";
import Login from "./Common/Containers/Login";
import Success from "./Common/Containers/Success";
import Verify from "./Common/Containers/Verify";

import Base from "./Common/Components/Base";
import LoaderComponent from "./Common/Components/LoaderComponent";
import Home from "./Common/Containers/Home";
import page404 from "./Common/Containers/page404";
import page500 from "./Common/Containers/page500";

import RouteChangeListener from "./Common/Components/RouteHandlingComponents/RouteChangeListener";

import Service from "./Services/Service";
import {faviconUpdate} from "./Services/checkWobotDomain";
import {getThemeColors} from "./themes";
import LogoutComponent from "./Common/Containers/logout";
import DriveThru from "./DriveThru/Containers/DriveThru";
import Model from "./Model/Container/Model";

function App(props) {
    toast.configure();

    const createDeviceInfo = () =>
        new Promise((resolve, reject) => {
            try {
                const deviceInfo = {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    deviceType: osName,
                    deviceId: uuid(),
                    name: `${deviceDetect().model ? deviceDetect().model : osName}`,
                };
                resolve(deviceInfo);
            } catch (e) {
                reject(e);
            }
        });
    // dev and staging are not discoverable by search engines
    useEffect(() => {
        faviconUpdate();
        if (process.env.REACT_APP_ENVIRONMENT !== "app") {
            const metaRobots = document.createElement("meta");
            metaRobots.name = "robots";
            metaRobots.content = "noindex,nofollow";
            document.head.appendChild(metaRobots);
        }
    }, []);

    const {deviceInfo} = props;

    useEffect(async () => {
        if (!deviceInfo) {
            try {
                const newDeviceInfo = await createDeviceInfo();
                props?.addDeviceInfo(newDeviceInfo);
            } catch (e) {
                showAlert(e);
            }
        }
    }, [deviceInfo]);

    // Change Theme
    useEffect(() => {
        const theme = getThemeColors(
            !Service.checkHost("openeye") ? "default" : "openEye"
        );
        Object.keys(theme).forEach((colorName) => {
            document.documentElement.style.setProperty(colorName, theme[colorName]);
        });
    }, []);

    return (
        <Router>
            <RouteChangeListener/>
            <LoaderComponent/>
            <section className="wobot-dashboard">
                <Switch>
                    <LoginRoutesComponent exact path="/" component={Base}/>
                    <LoginRoutesComponent exact path={LOGIN} component={Login}/>
                    <LoginRoutesComponent exact path={FORGET} component={Forget}/>
                    <LoginRoutesComponent exact path={[VERIFY, "/changepassword"]} component={Verify}/>
                    <LoginRoutesComponent exact path={SUCCESS} component={Success}/>
                    <PrivateRouteComponent exact path={HOME} component={Home}/>
                    <PrivateRouteComponent exact path={DRIVETHRU} component={DriveThru}/>
                    <PrivateRouteComponent exact path={MODELS} component={Model}/>
                    <PrivateRouteComponent exact path={LOCATION} component={Model}/>
                    <PrivateRouteComponent exact path={LOCATION_DETAILS} component={Model}/>
                    <PrivateRouteComponent exact path={LOGOUT} component={LogoutComponent}/>
                    <Route path={PAGE500} component={page500}/>
                    <Route path="*" component={page404}/>
                </Switch>
            </section>
        </Router>
    );
}

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
    toggleLoader: (data) => {
        dispatch(toggleLoaderAction(data));
    },
    addDeviceInfo: (data) => {
        dispatch(addDeviceInfoToStoreAction(data));
    },
    addUserDataToStore: (data) => {
        dispatch(addUserDataToStoreAction(data));
    },
    changeActiveSidebar: (data) => {
        dispatch(changeActiveSidebarAction(data));
    },
});

App.propTypes = {
    addDeviceInfo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
