import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.scss";

const PageTitleSkeleton = (props) => {
  return (
    <div className={"header-section"}>
      <div className={"flex-wrapper"}>
        <div className={"header-block"}>
          <Skeleton width={100} height={10} className={"mb-1"} />
          {/* Page Title */}
          <div className={"header-title--skeleton"}>
            {/* <span className={"rtsp-cursor mr-2"} onClick={()=>{history.goBack()}}><IconArrowLeft/></span> */}
            <Skeleton width={100} height={25} className={"mb-1"} />
            {/* <Skeleton count={2} /> */}
          </div>

          <p className={"sub-title"}>
            <Skeleton count={1} className={"mb-1"} />
          </p>
        </div>
        <Skeleton />
      </div>
    </div>
  );
};

export default PageTitleSkeleton;
