import React, { useEffect, useRef, useState } from "react";
import Col from "antd/es/col";
import {
  Card, Empty, Form, Input, Pagination, Select, Space, Spin, Switch, Tag,
} from "antd";
import Row from "antd/es/row";
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import { v4 } from "uuid";
import { io } from "socket.io-client";
import { SyncOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import Canvas from "./Canvas";
import {
  API_BASE_URL, API_BASE_URL_PUBLIC, APP_KEY, AUTHORIZATION, SOCKET_URL, TOKEN,
} from "../../Constants";
import CameraLog from "./CameraLog";

const { RangePicker } = DatePicker;

export default function DriveThruComponent() {
  const [env, setEnv] = useState({
    thruPut: {
      Api: API_BASE_URL,
      Key: APP_KEY,
      Authorization: AUTHORIZATION,
      SOCKET_URL,
      token: TOKEN,
      API_BASE_URL_PUBLIC,
    },
    personJourney: {
      Api: API_BASE_URL,
      Key: APP_KEY,
      Authorization: AUTHORIZATION,
      SOCKET_URL,
      token: TOKEN,
      API_BASE_URL_PUBLIC,
    },
  });
  const history = useHistory();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(20);
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [page, setPage] = useState(0);
  const [stationType, setStationType] = useState("");
  const [station, setStation] = useState("");
  const [query, setQuery] = useState("");
  const [pair, setPair] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [task, setTask] = useState("thruPut");
  const [timeZone, setTimeZone] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isLive, setIsLive] = useState(false);
  const socketRef = useRef();
  const [SocketData, setSocketData] = useState([]);
  const [SocketDebugData, setSocketDebugData] = useState([]);
  const [config, setConfig] = useState({});
  const [stationData, setStationData] = useState([]);
  const [stationAndType, setStationAndType] = useState([]);
  const [showLog, setShowLog] = useState(false);
  const [camera, setCamera] = useState([]);

  useEffect(() => {
    if (company && company.trim() !== "") {
      setLoading(true);
      getLocation(company);
    }
  }, [company]);

  useEffect(() => {
    form.setFieldsValue({ dateRange: [moment().subtract(1, "days").startOf("days"), moment().startOf("days")] });
    onEnvChange(task);
    getCompany(task);
    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (window.location.search) {
      const o = window.location.search.replace("?", "").trim().split("&");
      (o || []).forEach((val) => {
        if (val) {
          const obj = val.split("=");
          if (obj?.length && obj.length === 2) {
            switch (obj[0]) {
              case "task": {
                form.setFieldsValue({ task: obj[1] });
                setTask(obj[1]);
                break;
              }
              case "location": {
                form.setFieldsValue({ location: obj[1] });
                setLocation(obj[1]);
                break;
              }
              case "company": {
                form.setFieldsValue({ company: obj[1] });
                setCompany(obj[1]);
                break;
              }
              case "to": {
                setTo(decodeURIComponent(obj[1]));
                break;
              }
              case "from": {
                setFrom(decodeURIComponent(obj[1]));
                break;
              }
              case "limit": {
                setLimit(obj[1]);
                break;
              }
              case "page": {
                setPage(obj[1]);
                break;
              }
              case "timezone": {
                setTimeZone(decodeURIComponent(obj[1]));
                break;
              }
              case "stationType": {
                form.setFieldsValue({ stationType: obj[1] });
                setStationType(obj[1]);
                break;
              }
              case "station": {
                form.setFieldsValue({ station: decodeURIComponent(obj[1]) });
                setStation(decodeURIComponent(obj[1]));
                break;
              }
              case "query": {
                form.setFieldsValue({ query: decodeURIComponent(obj[1]) });
                setQuery(decodeURIComponent(obj[1]));
                break;
              }
              case "pair": {
                const val = +decodeURIComponent(obj[1]);
                val === 1 ? form.setFieldsValue({ pair: "Unpaired" }) : (val === 2 ? form.setFieldsValue({ pair: "Paired" }) : form.setFieldsValue({ pair: "All" }));
                setPair(obj[1]);
                break;
              }
              default: {
                break;
              }
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (to) {
      const dateRange = [moment(decodeURIComponent(from)), moment(decodeURIComponent(to))];
      form.setFieldsValue({ dateRange });
    }
    if (from) {
      const dateRange = [moment(decodeURIComponent(from)), moment(decodeURIComponent(to))];
      form.setFieldsValue({ dateRange });
    }
  }, [from, to]);

  const encodeUrl = (o) => {
    const keyValuePairs = [];
    for (const key in o) {
      if (o[key] || key === "pair") keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(o[key])}`);
    }
    const params = keyValuePairs.join("&");
    history.push(`${window.location.pathname}?task=${task}&${params}`);
    return params;
  };

  const addImageKeys = (imageUrl) => {
    const previewHostname = "wobotstagingoutputblob1.azureedge.net";
    const srsToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-07-31T22:01:43Z&st=2023-06-22T14:01:43Z&spr=https&sig=ZSp%2FAAo45kcmkMUudvbxJ12EduU6MmLEGWPsVMtE3lE%3D";
    const url = new URL(imageUrl);
    url.hostname = previewHostname;
    return url.href + srsToken;
  };

  const getCompany = (task) => {
    if (!task) return;
    const api = env[task];
    if (api && !Object.keys(api).length) return;
    setLoading(true);
    const url = `${api.API_BASE_URL_PUBLIC}/${task}/company`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: api.Authorization,
        key: api.Key,
      },
    }).then((results) => results.json()).then((json) => {
      if (json && json.data && json.data.length) {
        setCompanyData(json.data);
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  };

  const getData = (location, from, to, limit, page, stationType, station, pair, query) => {
    if (loading) return;
    if (!location) return;
    if (!from) return;
    if (!to) return;
    if (!task) return;
    const api = env[task];
    if (!Object.keys(api).length) return;

    const obj = {
      company,
      location,
      from,
      to,
      limit,
      page,
      stationType,
      timezone: timeZone,
      station,
      pair,
      query,
    };
    setData({});
    setLoading(true);

    const url = `${api.Api}/${task === "thruPut" ? "drivethru" : "customer"}/preprocess?${encodeUrl(obj)}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: api.Authorization,
        key: api.Key,
      },
    }).then((results) => results.json()).then((json) => {
      setData(json.data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  };

  const onSubmit = (e) => {
    if (!loading) {
      const { location } = e;
      const from = e.dateRange[0].format("YYYY-MM-DDTHH:mm:ss");
      const to = e.dateRange[1].format("YYYY-MM-DDTHH:mm:ss");
      /* setTask(e.task); */
      setLocation(location);
      setFrom(from);
      setStationType(e.stationType);
      setTo(to);
      setStation(e.station);
      setPair(e.pair);
      setQuery(e.query);
      getData(location, from, to, limit, page, e.stationType, e.station, e.pair, e.query);
    }
  };

  const connectSocket = (company, location) => {
    const options = {
      room: location, roomType: "location",
    };
    const api = env[task];
    socketRef.current = io(api.SOCKET_URL, {
      path: "/socket",
      auth: {
        token: api.token, tz: "Asia/kolkata", deviceId: v4(), deviceType: "browser",
      },
      transports: ["websocket", "polling"], // required
      query: {
        companyId: company, locationId: location, deviceUserType: "wmsEdgeDebug",
      },
    });

    socketRef.current?.on("connect", () => {
      // socketInfo(socketRef);
      setIsLive(true);
    });
    socketRef.current?.on("connect_error", (error) => {
      console.log(error);
      setIsLive(false);
    });
    socketRef.current?.emit("subscribeToRoom", options, (err) => {
      if (err) {
        console.log(err);
      }
      console.log(options);
      console.log("connected to room");
    });
    socketRef.current?.on("drivethruHailo:detection", (payload) => {
      const { data, error } = payload;
      if (!error && data) {
        setSocketData((prevState) => {
          const soc = [data].concat(prevState);
          return [...soc];
        });
      }
    });
    socketRef.current?.on("drivethruHailo:debug", (payload) => {
      const { data, error } = payload;
      if (!error && data) {
        if (data?.payloadType === "detection") {
          setSocketData((prevState) => {
            const soc = [...prevState].findIndex((o) => o.id === data?.id);
            if (soc > -1) {
              const arr = [...prevState];
              arr[soc].frame = data?.frame;
              return [...arr];
            }
            return prevState;
          });
        } else {
          setSocketDebugData((prevState) => {
            const soc = [data].concat(prevState);
            return [...soc];
          });
        }
      }
    });
    socketRef.current?.on("disconnect", (reason) => {
      console.log(reason);
      setIsLive(false);
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socketRef?.current?.connect();
      }
    });
  };

  const getLocation = (companyId) => {
    if (!companyId) return;
    const api = env[task];
    if (api && !Object.keys(api).length) return;
    const url = `${api.API_BASE_URL_PUBLIC}/drivethru/location?company=${companyId}&type=location`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: api.Authorization,
        key: api.Key,
      },
    }).then((results) => results.json()).then((json) => {
      if (json && json.data && json.data.length) {
        setLocationData(json.data);
        if (location) getConfig(location);
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  };

  const getConfig = (locationId) => {
    if (!locationId || !company) return;
    const api = env[task];
    if (api && !Object.keys(api).length) return;
    setStationAndType([]);
    setStationData([]);
    const url = `${api.API_BASE_URL_PUBLIC}/drivethru/config?location=${locationId}&company=${company}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: api.Authorization,
        key: api.Key,
      },
    }).then((results) => results.json()).then((json) => {
      if (json && json.data) {
        const arr = [];
        Object.keys(json.data.stations).forEach((i) => {
          const data = {
            key: json.data.stations[i]?.stationType, val: [],
          };
          Object.keys(json.data.stations[i]).forEach((j) => {
            if (json.data.stations[i][j]?.camera_0) {
              setCamera((prevState) => {
                const old = [...prevState];
                const ex = old.find((o) => o.value === json.data.stations[i][j]?.camera_0?.cameraId);
                if (!ex) {
                  old.push({
                    value: json.data.stations[i][j]?.camera_0?.cameraId,
                    label: json.data.stations[i][j]?.camera_0?.cameraName,
                  });
                }
                return [...old];
              });
            }
            if (json.data.stations[i][j]?.stationName) {
              if (data.val) data.val = data.val.concat(json.data.stations[i][j]?.stationName);
            }
          });
          arr.push(data);
        });
        setConfig(json.data);
        setStationAndType(arr);
        if (stationType) {
          setStationData((prevState) => {
            const selectedStation = arr.filter((o) => o.key === stationType)[0];
            return selectedStation?.val;
          });
        }
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  };

  const onChange = (val) => {
    getData(location, from, to, limit, val - 1, stationType, station, pair, query);
  };

  const onCompanyChange = (e) => {
    setLoading(true);
    setData({});
    setLocationData([]);
    setLocation(null);
    setStationAndType([]);
    setCompany(e);
    getLocation(e);
  };

  const onLocationChange = (e) => {
    setData({});
    setLocation(e);
    setStation(null);
    if (locationData && locationData.length) {
      const timeZ = locationData.find((o) => o._id === e);
      setTimeZone(timeZ.timezone);
    }
    if (isLive) Live(false);
    getConfig(e);
  };

  const onStationTypeChange = (e) => {
    setStationType(e);
    setStationData((prevState) => {
      const selectedStation = stationAndType.filter((o) => o.key === e)[0];
      return selectedStation?.val || "";
    });
  };

  const onStationChange = (e) => {
    setStation(e);
  };

  const onPairChange = (e) => {
    setPair(e);
  };

  const onEnvChange = (e) => {
    // setLoading(true);
    setTask(e);
    getCompany(e);
    if (isLive) {
      disconnect();
    }
    setData({});
  };

  const convertToLocalTime = (time) => {
    if (time && timeZone) return moment(time).parseZone().format("MMM Do YYYY, h:mm:ss a");
  };

  const getTimeDiff = (t1, t2) => {
    if (!t1 || !t2) return;
    const a = moment(t1);
    const b = moment(t2);
    const seconds = a.diff(b, "seconds");
    if (seconds < 60) {
      return `${seconds + 1} secs`;
    }
    const minutes = a.diff(b, "minutes");
    const cc = moment(t2).add(minutes, "minutes");
    const ss = a.diff(cc, "seconds");

    return `${minutes} min ${ss} secs`;
  };

  function EntryBox({ data, config, stationId }) {
    return (
      <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }} hoverable bordered>
        <Canvas
          url={data?.image}
          boundingBox={data?.boundingBox}
          config={config}
          camera={data?.camera}
          roi={data?.roi}
          lineCrossingExit={data?.lineCrossingExit}
          lineCrossingEntry={data?.lineCrossingEntry}
          poi={data?.poi}
          stationId={stationId}
          showGrade
        />
        <br />
        <Space direction="vertical" size="small" style={{ margin: "12px" }}>
          <Space size="small">
            <Tag
              color="#3766e8"
              style={{ textTransform: "capitalize", marginRight: 0 }}
            >
              {data?.stationType}
            </Tag>
            <Tag
              color="green"
              style={{ textTransform: "capitalize", fontWeight: 500 }}
            >
              Entry
            </Tag>
            <Tag
              color="#fba700"
              style={{ textTransform: "capitalize", fontWeight: 500 }}
            >
              {data?.confidence}
            </Tag>
          </Space>
          <Tag color="blue">{convertToLocalTime(data?.time)}</Tag>
          <Space size="small">
            {task === "thruPut" && <Tag color="geekblue">{data?.lp}</Tag>}
            {task === "personJourney" && <Tag color="geekblue">{data?.customerId}</Tag>}
            <Tag color="gold" style={{ textTransform: "capitalize" , marginLeft: 0}}>{data?.uid}</Tag>
          </Space>
        </Space>
      </Card>
    );
  }

  function ExitBox({
    data, config, time, stationId,
  }) {
    return (
      <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }} hoverable bordered>
        <Canvas
          url={data?.image}
          boundingBox={data?.boundingBox}
          config={config}
          camera={data?.camera}
          roi={data?.roi}
          lineCrossingExit={data?.lineCrossingExit}
          lineCrossingEntry={data?.lineCrossingEntry}
          poi={data?.poi}
          stationId={stationId}
        />
        <br />
        <Space direction="vertical" size="small" style={{ margin: "12px" }}>
          <Space size="small">
            <Tag
              color="#3766e8"
              style={{ textTransform: "capitalize", marginRight: 0 }}
            >
              {data?.stationType}
            </Tag>
            <Tag color="red" style={{ textTransform: "capitalize", fontWeight: 500 }}>Exit</Tag>
          </Space>
          <Tag color="blue">{convertToLocalTime(data?.time)}</Tag>
          <Tag
            color="purple"
            style={{ textTransform: "capitalize" }}
          >
            {getTimeDiff(data?.time, time)}
          </Tag>
        </Space>
      </Card>
    );
  }

  function NoBox() {
    return (
      <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: "260px", width: "320px" }}
          description={(
            <span>
              No Image
            </span>
                    )}
        />
      </Card>
    );
  }

  const getStation = (arr) => {
    if (arr && arr.length !== 2) {
      return [...arr, {}];
    }
    return arr || [];
  };

  function ContainerBox({ obj, config }) {
    return (
      <Col>
        <Space size="small" align="start">
          {getStation(obj.stations).sort((a) => {
            const en = a?.type;
            const ex = a?.type;
            if (en === "entry") return -1;
            if (ex === "exit") return 1;
            return 0;
          }).map((data, index) => {
            if (data?.type === "entry") {
              return (
                <EntryBox
                  data={data}
                  key={data?._id}
                  config={config}
                  stationId={data?.stationId}
                />
              );
            }
            if (data?.type === "exit") {
              return (
                <ExitBox
                  data={data}
                  key={data?._id}
                  config={config}
                  stationId={data?.stationId}
                  time={obj.stations[0].time}
                />
              );
            }
            return <NoBox key={index} />;
          })}
        </Space>
      </Col>
    );
  }

  function SocketBox({ data }) {
    return (
      <Col>
        <Space size="small" align="start">
          <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
            <img
              width="200px"
              height="200px"
              src={data?.frame ? addImageKeys(data?.frame) : "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
              alt=""
            />
            <br />
            <Space direction="vertical" size="small" style={{ margin: "12px" }}>
              <Space size="small">
                <Tag
                  color="#3766e8"
                  style={{ textTransform: "capitalize", marginRight: 0 }}
                >
                  {data?.stationName}
                </Tag>
                <Tag
                  color={data?.triggerType?.toLowerCase() === "exit" ? "red" : "green"}
                  style={{
                    textTransform: "capitalize", fontWeight: 500,
                  }}
                >
                  {data?.triggerType}
                </Tag>
              </Space>
              <Tag color="blue">{convertToLocalTime(data?.time)}</Tag>
              <Tag color="gold" style={{ textTransform: "capitalize" }}>{data?.uid}</Tag>
            </Space>
          </Card>
        </Space>
      </Col>
    );
  }

  const disconnect = () => {
    if (!location) return;
    const options = {
      room: location, roomType: "location",
    };

    socketRef?.current?.emit("unsubscribeFromRoom", options, (err) => {
      if (err) {
        console.log(err);
      }
    });
    socketRef?.current?.disconnect();
    setSocketData([]);
    setIsLive(false);
  };

  const Live = (e) => {
    if (!company || !location) return;

    if (!e && socketRef?.current?.connected) {
      const options = {
        room: location, roomType: "location",
      };

      socketRef?.current?.emit("unsubscribeFromRoom", options, (err) => {
        if (err) {
          console.log(err);
        }
      });
      socketRef?.current?.disconnect();
      setSocketData([]);
      setIsLive(!isLive);
    } else connectSocket(company, location);
  };

  useEffect(() => {
    const obj = {
      company, location, from, to, limit, page, stationType, timezone: timeZone,
    };
    encodeUrl(obj);
    if (location && from && to && limit && stationType && station) {
      setTimeout(() => {
        getData(location, from, to, limit, 0, stationType, station, pair, query);
      }, 200);
    }
  }, [company, location, stationType, from, to, station, pair, query]);

  const showModel = () => {
    setShowLog(true);
  };
  const hideModel = () => {
    setShowLog(false);
  };

  const getExtra = (data) => (
    <div>
      {data?.total > 0 && (
        <Tag
          color="purple"
          style={{
            textTransform: "capitalize",
            fontWeight: 500,
            cursor: "pointer",
          }}
          onClick={() => {
            showModel();
          }}
        >
          View camera logs
        </Tag>
      )}
      {data?.total > 0 && (
        <Tag
          color="blue"
          style={{
            textTransform: "capitalize", fontWeight: 500,
          }}
        >
          {" "}
          {data?.paired}
          /
          {" "}
          {data?.total}
          {" "}
          Paired Events
        </Tag>
      )}
    </div>
  );

  return (
    <div className="p-20">
      {loading && (
        <div style={{
          zIndex: 2,
          position: "absolute",
          overflow: "hidden",
          height: "100%",
          width: "100%",
          paddingTop: "10%",
          paddingLeft: "50%",
        }}
        >
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      )}
      <Row gutter={[36, 36]} style={{ margin: 0 }}>
        <Form onFinish={onSubmit} form={form} layout="inline">
          <Form.Item
            name="task"
            rules={[{
              required: true, message: "Select Task",
            }]}
            style={{ minWidth: "180px", marginBottom: "10px" }}
          >
            <Select
              placeholder="Select Task"
              optionFilterProp="children"
              options={[{
                value: "thruPut", label: "ThruPut",
              }, {
                value: "personJourney", label: "Person journey",
              }]}
              onChange={onEnvChange}
            />
          </Form.Item>
          <Form.Item
            name="company"
            rules={[{
              required: true, message: "Select a company",
            }]}
            style={{ minWidth: "180px", marginBottom: "10px" }}
          >
            <Select
              placeholder="Select company"
              optionFilterProp="children"
              options={(companyData || []).map((obj) => ({
                value: obj?._id, label: obj?.company,
              }))}
              showSearch
              onChange={onCompanyChange}
              filterOption={(input, option) => (option?.label).toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>

          <Form.Item
            name="location"
            rules={[{
              required: true, message: "Select a location",
            }]}
            style={{ minWidth: "240px", marginBottom: "10px" }}
          >
            <Select
              placeholder="Select location"
              optionFilterProp="children"
              options={(locationData || []).map((obj) => ({
                value: obj?._id, label: `${obj?.area} (${obj?.timezone})`,
              }))}
              onChange={onLocationChange}
              showSearch
              filterOption={(input, option) => (option?.label).toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>

          <Form.Item
            name="stationType"
            rules={[{
              message: "Select the station",
            }]}
            style={{ minWidth: "80px", marginBottom: "10px" }}
          >
            <Select
              placeholder="Select stationType"
              options={(stationAndType || []).map((obj) => ({
                value: obj.key, label: obj.key,
              }))}
              onChange={onStationTypeChange}
              showSearch
              allowClear
              filterOption={(input, option) => (option?.label).toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>

          <Form.Item
            name="station"
            rules={[{
              message: "Select the lane",
            }]}
            style={{ minWidth: "80px", marginBottom: "10px" }}
          >
            <Select
              placeholder="Select lane"
              options={(stationData || []).map((obj) => ({
                value: obj, label: obj,
              }))}
              onChange={onStationChange}
              showSearch
              allowClear
              filterOption={(input, option) => (option?.label).toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>

          <Form.Item
            name="pair"
            style={{ minWidth: "90px", marginBottom: "10px" }}
          >
            <Select
              placeholder="Select pair"
              options={[{
                value: 0, label: "All",
              }, {
                value: 1, label: "Unpaired",
              }, {
                value: 2, label: "Paired",
              }]}
              onChange={onPairChange}
              showSearch
              filterOption={(input, option) => (option?.label).toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>

          <Form.Item
            name="dateRange"
            rules={[{
              required: true, message: "Select daterange",
            }]}
            style={{ marginBottom: "10px" }}
          >
            <RangePicker showTime />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="middle"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
          {data?.total > 0 ? (
            <Form.Item>
              <a
                href={`${env[task]?.Api}/drivethru/preprocess/download${window.location.search}`}
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            </Form.Item>
          ) : <></>}
          <Form.Item>
            <Switch
              size="small"
              checkedChildren="Live"
              unCheckedChildren="Live"
              defaultChecked
              style={{ marginLeft: "8px" }}
              onChange={(e) => Live(e)}
              checked={isLive}
            />
          </Form.Item>

          <Form.Item
            name="query"
            rules={[{
              message: "Search by CarId, Uid",
            }]}
            style={{ minWidth: "112px", marginBottom: "10px" }}
          >
            <Input allowClear placeholder="Search by CarId/UID" />
          </Form.Item>
        </Form>
      </Row>
      {isLive && (
        <Tag icon={<SyncOutlined spin />} color="processing" style={{ margin: "12px 0" }}>
          Loading data via Socket...
        </Tag>
      )}
      {isLive && (
        <Card title="Live Data" bordered={false} bodyStyle={{ padding: 0 }}>
          <Row gutter={[36, 36]} style={{ margin: 0 }}>
            {(SocketData || []).map((obj) => <SocketBox data={obj} key={obj?._id} />)}
          </Row>
        </Card>
      )}
      <div className="space30" />
      {isLive && (
      <Card title="Debug Data" bordered={false} bodyStyle={{ padding: 0 }}>
        <Row gutter={[36, 36]} style={{ margin: 0 }}>
          {(SocketDebugData || []).map((obj) => <SocketBox data={obj} key={obj?._id} />)}
        </Row>
      </Card>
      )}
      <div className="space30" />
      <Card
        title="Detections"
        bordered={false}
        bodyStyle={{ padding: 0 }}
        extra={getExtra(data)}
      >
        <Row gutter={[36, 36]} style={{ margin: 0 }}>
          {
            (data?.data || []).map((obj, index) => <ContainerBox obj={obj} key={index} config={config} />)
          }
        </Row>

        <div className="mb-2" />
        <Row gutter={[36, 36]} style={{ marginLeft: 15 }}>
          {data?.data && data?.data?.length
            ? (
              <Pagination
                defaultCurrent={data.page + 1}
                total={data.total}
                onChange={onChange}
                onShowSizeChange={(current, size) => {
                  setLimit(size);
                  getData(location, from, to, size, 0, stationType, pair);
                }}
                defaultPageSize={limit}
              />
            ) : null}
        </Row>
      </Card>

      <div className="space30" />
      <div className="space30" />
      {showLog ? (
        <CameraLog
          handleShowLog={hideModel}
          showLog={showLog}
          camera={camera}
        />
      ) : null}

    </div>
  );
}
