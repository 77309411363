/**
 * Converts a given number of seconds into a formatted time string.
 *
 * @param {number} seconds - The number of seconds to be converted.
 * @returns {string} The formatted time string.
 */

export function convertSecondsInclFracPart(value) {
  const seconds = parseFloat(value);
  // Parse as float to handle fractional seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds / 60) % 60); // Calculate minutes with fractional part
  const secondsRemaining = Math.round(seconds % 60); // Round the remaining seconds

  if (!!hours) {
    if (!!minutes) {
      return `${hours}h ${minutes}m ${secondsRemaining}s`;
    }
    return `${hours}h ${secondsRemaining}s`;
  }

  if (!!minutes) {
    return `${minutes}m ${secondsRemaining}s`;
  }

  return `${secondsRemaining}s`;
}

export function convertSeconds(seconds) {
  var seconds = parseInt(seconds, 10);
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  var seconds = seconds - hours * 3600 - minutes * 60;
  if (!!hours) {
    if (!!minutes) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      return `${hours}h ${seconds}s`;
    }
  }

  if (!!minutes) {
    return `${minutes}m ${seconds}s`;
  }

  return `${seconds}s`;
}

export function convertSecondsToHrsMinsSecs(seconds, displaySeconds = false) {
  seconds = parseInt(seconds, 10);

  // Calculate hours, minutes, and seconds.
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Helper function to format the time units.
  const formatUnit = (value, singular, plural) => {
    return value === 1 ? `${value} ${singular}` : `${value} ${plural}`;
  };

  let result = [];

  // If hours, minutes or seconds are present, push the formatted data to the result.
  // Don't show minutes or seconds if hours >= 10 and handle for displaySeconds flag.
  if (hours >= 10) {
    result.push(formatUnit(hours, "hr", "hrs"));
  } else {
    if (hours) result.push(formatUnit(hours, "hr", "hrs"));
    if (minutes) result.push(formatUnit(minutes, "min", "mins"));
    if (displaySeconds && seconds)
      result.push(formatUnit(seconds, "sec", "secs"));
  }

  return result.join(" ");
}
