import React, { useCallback, useEffect, useState } from "react";
import { convertSecondsToHrsMinsSecs } from "../../../../Services/convertSeconds";
import { store } from "../../../../Store";
import styles from "./StyleNotifyStrip.module.scss";

function NotificationStrip(props) {
  const { alertData, userData } = store.getState();
  const [contentMessage, setContentMessage] = useState("");

  const getMessage = (
    paramModule,
    consumedPercentage,
    paramLimit,
    paramUsed
  ) => {
    let message = "";
    if (consumedPercentage === 100) {
      message = `You've reached 100% of the monthly ${paramUsed} hours limit for ${paramModule === "RecordingTask" ? "playback recording" : "AI task"
        } processing (processing paused).`;
    } else if (consumedPercentage >= 90) {
      message = `You've reached ${consumedPercentage}% of ${paramModule === "RecordingTask" ? "Recording Task" : "AI Task"
        } processing hours this month (${convertSecondsToHrsMinsSecs(
          (paramLimit - paramUsed) * 3600
        )} remaining).`;
    }
    return message;
  };

  const processAlertData = useCallback((data) => {
    let content = "";

    const processLimit = (limit) => {
      if (limit) {
        content += getMessage(
          limit?.module,
          limit?.consumedPercentage,
          limit?.limit,
          limit?.used
        );
      }
    };
    data.forEach(({ openeyeAlert, recordingLimit, taskLimit } = {}) => {
      if (openeyeAlert?.alertMessage) {
        content += openeyeAlert.alertMessage;
        return;
      }
      processLimit(recordingLimit);
      processLimit(taskLimit);
    });

    return content;
  }, []);

  useEffect(() => {
    if (alertData && alertData.length > 0) {
      const content = processAlertData(alertData);
      setContentMessage(content);
    } else {
      setContentMessage("");
    }
  }, [userData, alertData]);

  return (
    <div hidden={!props?.showNotifyContent}>
      <div
        className={`fixedTop_width ${styles.fixedTop_strip} ${styles.primary_bg}`}
      >
        <div className={`${styles.notify_strip_wrapper}`}>
          <label className={styles.message_text}>
            <span>{props?.notifyIcon}</span>
            {contentMessage}
          </label>
          {userData.user.role === "Owner" || userData.user.role === "Admin" ? (
            <a href="mailto:support@wobot.ai" className={styles.contact_link}>
              Contact Support
            </a>
          ) : (
            <label className={`mb-0 ${styles.contact_text}`}>
              Contact Admin
            </label>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationStrip;
