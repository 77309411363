import React, { useEffect } from "react";
import VerifyComponent from "../Components/ForgotPasswordComponent/VerifyComponent";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../Constants";

const Verify = (props) => {
  const history = useHistory();
  useEffect(() => {
    if (
      !props?.location?.state?.user?.email ||
      !props?.location?.state?.user?.id
    ) {
      history.push(LOGIN);
    }
  }, [props]);

  return (
    <VerifyComponent
      user={
        props &&
        props.location &&
        props.location.state &&
        props.location.state.user
          ? props.location.state.user
          : null
      }
    />
  );
};

export default Verify;
