import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { API_BASE_URL, API_CONFIG_SHOWLOADER } from "../../../Constants";
import {
  CHECKLISTEDIT,
  CHECKLISTVIEW,
  SETTINGSMANAGEEMPLOYEE,
  WOCAMACCESSLIVEVIEW,
  WOCAMVIEWANDTAGRECORDINGS,
  WOCAMVIEWCAMERA,
} from "../../../Constants/AccessList";
import { home } from "../../../Constants/MetaDetails";
import Roles from "../../../Services/Roles";
import callApi from "../../../Services/callApi";
import { showAlert } from "../../../Services/showAlert";
import { store } from "../../../Store";
import { addAlertDataToStoreAction } from "../../../Store/actions";
import HomeSkeleton from "../SkeletonComponent/MainModulesSkeleton/HomeSkeleton";
import Card from "./CardComponent";
import PageTitle from "../Molecule/PageTitle";

function HomeComponent(props) {
  const prevUserId = useSelector((state) => {
    const { _id: userId } = state?.userData?.manifest || {};
    return userId;
  });

  const userName = props?.userData?.user?.name || "user";
  const pageTitleContent = `${home?.title} ${userName}`;

  useEffect(() => {
    // if user data changed in redux and if user is already on home page,
    // it will deduct the changes and home page API will called
    const unsubscribe = store?.subscribe(() => {
      const {
        userData,
        loader: { value: showLoader },
      } = store?.getState() || {};
      const { _id: currentUserId } = userData?.manifest || {};
      if (currentUserId !== prevUserId && !showLoader) {
        getDetails();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [prevUserId]);

  return (
    <div className="wobot-panel-main">
      {props?.loader.value ? (
        <HomeSkeleton />
      ) : (
        <div className="main-body-wrapper">
          <PageTitle
            pageTitle={pageTitleContent}
            showSubTitle={false}
            subTitle={home?.subtitle}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
  setAlertData: (data) => dispatch(addAlertDataToStoreAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
