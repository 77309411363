import React, { lazy, Suspense } from "react";

import { Route, Switch } from "react-router-dom";
import { PAGE_TITLES } from "../../Services/checkWobotDomain";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";
import { LOCATION, LOCATION_DETAILS, MODELS } from "../../Constants";
import ModelContextProvider from "../../Common/Contexts/ModelContextProvider";
import ModelLayout from "../../Common/Components/ModelLayout";
import CameraGridSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/CameraGridSkeleton";

const ModelComponent = lazy(() => import("../Components/index"));
const LocationComponent = lazy(() => import("../Components/location"));
const LocationDetailsComponent = lazy(() => import("../../LocationDetails/Components/index"));

function Model(props) {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.model}
      meta=""
    >
      <ModelContextProvider>
        <ModelLayout>
          <Switch>
            <Route exact path={MODELS}>
              <Suspense fallback={<CameraGridSkeleton />}>
                <ModelComponent />
              </Suspense>
            </Route>
            <Route exact path={LOCATION}>
              <Suspense fallback={<CameraGridSkeleton />}>
                <LocationComponent />
              </Suspense>
            </Route>
            <Route exact path={LOCATION_DETAILS}>
              <Suspense fallback={<CameraGridSkeleton />}>
                <LocationDetailsComponent locationId={props?.match?.params?.locationId} />
              </Suspense>
            </Route>
          </Switch>
        </ModelLayout>
      </ModelContextProvider>
    </PageContainer>
  );
}

export default Model;
