import { isPossiblePhoneNumber } from "react-phone-number-input";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email);
};

const validateMobile = (mobile) => {
  const mob = isPossiblePhoneNumber(mobile);
  // const mob = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  // return !mob.test(mobile);
  return !mob;
};

const validatePassword = (password) =>
  password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);

const validateIPaddress = (inputText) => {
  const ipformat =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipformat.test(inputText);
};

const validateEmpty = (value) => {
  if (typeof value === "string") {
    return value.trim() === "";
  }
};

const validateDropDown = (option) => !(option && option.length);

const validateRTSPUrl = (strUrl) => {
  // const url =
  //   /(rtsp|https?):\/\/(?:([^\s@\/]+)@)?([^\s\/:]+)(?::([0-9]+))?(?:\/(.*))?/;

  // validate,if url starts with http or https then it should end with .m3u8, else validate rtsp url
  const url =
    /((?:https?):\/\/(?:(?:[^\s@/]+)@)?(?:[^\s/:]+)(?::(?:[0-9]+))?(?:\/(?:.*?\.m3u8))|(rtsp):\/\/(?:(?:[^\s@/]+)@)?(?:[^\s/:]+)(?::(?:[0-9]+))?(?:\/(?:.*))?)/;
  return url.test(strUrl);
};

const validateSearch = (searchStr) => {
  const search = /[\(\*\)\\\[\?][\%]/;
  return !search.test(searchStr);
};

// Validate Camera Name Input
const validateName = (inputNameStr) => {
  if (inputNameStr) {
    const name = /^[a-zA-Z][a-zA-Z0-9 ]*(?:-[a-zA-Z0-9 ]+)*$/;
    return name.test(inputNameStr);
  }
  return true;
};
export {
  validateDropDown,
  validateEmail,
  validateEmpty,
  validateIPaddress,
  validateMobile,
  validateName,
  validatePassword,
  validateRTSPUrl,
  validateSearch,
};
