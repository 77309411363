import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import PageTitle from "../Molecule/PageTitle";
import TabLink from "../Molecule/TabLink";
import styles from "./style.module.scss";
import {LOCATION, MODELS} from "../../../Constants";
import {IconAdd} from "../IconsComponent/Index";
import {useModelContext} from "../../Contexts/ModelContextProvider";
import SearchFilter from "../Molecule/SearchFilter";
import ActionBlock from "../Molecule/ActionBlock";

function ModelLayout({children}) {
    const location = useLocation();
    const {handleSearch, searchQuery} = useModelContext();
    const {showModel, hideModel} = useModelContext();
    const handleTabClick = () => {
        handleSearch('');
    };
    const setShowModel = () => {
        hideModel(!showModel);
    };

    return (
        <div className="wobot-panel-main">
            <div className="main-body-wrapper crumb-cursor-type">
                <PageTitle
                    pageTitle="Model Management"
                    breadcrumb={[{name: "Model Management"}]}
                    showSubTitle
                    subTitle="Map AI models to locations here for optimized processing"
                >
                    <ActionBlock showActionList={false} showActionBtn>
                        { location.pathname === MODELS && <li className="list-inline-item ">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm btn-text-icon"
                                onClick={setShowModel}
                            >
                              <span className="icon-md">
                                <IconAdd/> Add New
                              </span>
                            </button>
                        </li>}
                        <li className="list-inline-item">
                            <SearchFilter
                                value={searchQuery}
                                handleUpdateValue={handleSearch}
                            />
                        </li>
                    </ActionBlock>
                </PageTitle>

                <div className={` d-flex justify-content-between align-items-center ${styles.tab_container}`}>
                    <TabLink
                        onClick={handleTabClick}
                        currentPath={location.pathname}
                        tabList={[
                            {
                                name: "Models",
                                link: MODELS,
                                key: "models"
                            },
                            {
                                name: "Locations",
                                link: LOCATION,
                                key: "locations"
                            },
                        ]}
                        className={styles.tab_list_container}
                    />
                </div>
                {children}
            </div>
        </div>
    );
}

ModelLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
export default ModelLayout;
