import callApi from "../../../Services/callApi";
import { API_BASE_URL, API_CONFIG_NO_MANIFEST_CALL } from "../../../Constants";

export default async function fetchTaskLog(payload) {
  const { data, message, status } = await callApi(
    `${API_BASE_URL}/camera/${payload?.camera}`,
    null,
    API_CONFIG_NO_MANIFEST_CALL
  );
  return {
    message,
    status,
    ...data,
  };
}
