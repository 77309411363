import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { checkWobotDomain } from "../../../../Services/checkWobotDomain";
import usePageTitle from "../../../Hooks/usePageTitle";
import Layout from "../Home/index";

/**
 * PageContainer is a component that manages the page title and metadata for a specific page.
 * It wraps the provided child component, enhancing it with the ability to update the page title.
 *
 * @component
 * @example
 * // Usage in a parent component:
 * <PageContainer initialPageTitle="Home Page" meta="This is the home page">
 *   <HomePageContent />
 * </PageContainer>
 *
 * @param {Object} props - The properties of the component.
 * @param {React.ReactNode} props.children - The child component to be wrapped.
 * @param {string} props.initialPageTitle - The initial title of the page.
 * @param {string} [props.meta=""] - Additional meta information for the page.
 * @returns {JSX.Element} The JSX representation of the component.
 */
function PageContainer({ children, initialPageTitle, meta = "" }) {
  const { pageTitle, setPageTitle } = usePageTitle(initialPageTitle);
  const childElement = React.Children.only(children);

  const childWithProps = React.cloneElement(childElement, {
    setPageTitle,
    pageTitle,
  });
  return (
    <>
      <Helmet>
        <title>{checkWobotDomain(pageTitle)}</title>
        <meta name="description" content={meta} />
      </Helmet>
      <Layout>{childWithProps}</Layout>
    </>
  );
}
PageContainer.defaultProps = { meta: "" };
PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  initialPageTitle: PropTypes.string.isRequired,
  meta: PropTypes.string,
};

export default PageContainer;
