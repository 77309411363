import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../IconsComponent/IconClose";
/**
 * Modal component.
 *
 * @param {Object} props - The component's props.
 * @param {("sm"|"lg"|"xl")} props.modalSize - The size of the modal (sm, lg, xl).
 * @param {boolean} props.isOpen - Controls the visibility of the modal.
 * @param {Function} props.onHide - Callback when the modal is closed.
 * @param {boolean} props.fullscreen - To show modal in full width.
 * @param {boolean} props.centered - To show the modal in center.
 * @param {string} props.dialogClassName - Additional CSS classes for the modal dialog.
 * @param {string} props.headerTitle - The title of the modal.
 * @param {string} props.headerTitleSubText - Text to show next to title.
 * @param {string} props.headerSubtitle - Text for the bottom element.
 * @param {ReactNode} props.children - The content to display.
 * @param {boolean} props.isFooter - Whether to display the modal footer.
 *
 * @param {Object} props.buttons - An object containing header and footer buttons.
 * @param {Button} props.buttons.headerButtons - Buttons to display in the header.
 * @param {Button} props.buttons.footerButtons - Buttons to display in the footer.
 * @typedef {Object[]} Button
 * @property {string} Button.name - The name of the button.
 * @property {Function} Button.onClick - Callback function when the button is clicked.
 * @property {Node} Button.icon - (Optional) icon component to display alongside the button.
 * @property {string[]} Button.classes - Array of CSS classes for styling the button.
 * @property {boolean} Button.isPrimary - If primary, align to the right.
 *
 */

const renderButtons = (buttonList) => {
  if (!buttonList?.length) {
    return null;
  }
  const isPrimaryButton = (button) => (button.isPrimary ? 0 : 1);
  const sortedButtons = [...buttonList].sort(
    (a, b) => isPrimaryButton(a) - isPrimaryButton(b)
  );

  return (
    <>
      {sortedButtons.map(({ name, onClick, icon, classes }) => (
        <li key={name} className="list-inline-item">
          <button
            type="button"
            className={`btn btn-sm ${classes.join(" ")}`}
            onClick={onClick}
          >
            {icon && <span>{icon()}</span>} {name}
          </button>
        </li>
      ))}
    </>
  );
};

function ModalBlock(props) {
  const {
    modalSize,
    fullscreen,
    centered,
    isOpen,
    onHide,
    dialogClassName,
    headerTitle,
    headerTitleSubText,
    headerSubtitle,
    buttons,
    children,
    isFooter,
    dialogBodyClassName,
  } = props;
  const buttonMap = {
    header: buttons?.headerButtons || [],
    footer: buttons?.footerButtons || [],
  };

  return (
    <Modal
      size={modalSize}
      fullscreen={fullscreen}
      centered={centered}
      show={isOpen}
      onHide={onHide}
      dialogClassName={`config-modal height-auto ${dialogClassName}`}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="border-0 mb-0 align-items-top d-flex align-items-center">
        <div className="modal-header-block">
          <div className="header-title justify-content-start mb-2 flex-1">
            <h5 className="mb-0 title-tag fw-500 fs-22 text-primary">
              {headerTitle}
            </h5>
            {headerTitleSubText && (
              <span className="ml-2 fs-14 text-other">
                {headerTitleSubText}
              </span>
            )}
          </div>
          {headerSubtitle && (
            <p className="mb-0 fs-14 fw-400 text-other">{headerSubtitle}</p>
          )}
        </div>
        {/* Header buttons */}
        <div className="modal-header-action">
          <ul className="lint-inline">
            {renderButtons(buttonMap.header)}
            <li className={`${"list-inline-item"}`}>
              <button
                type="button"
                className={`${"btn btn-single-icon btn-sm"}`}
                onClick={onHide}
              >
                <IconClose />
              </button>
            </li>
          </ul>
        </div>
      </Modal.Header>
      <Modal.Body className={`mb-0 config-container ${dialogBodyClassName}`}>
        <div className={`${"config-block-wrapper"}`}>{children}</div>
      </Modal.Body>
      {isFooter && (
        <Modal.Footer className="pure-white-bg p-4">
          <ul className="lint-inline">{renderButtons(buttonMap.footer)}</ul>
        </Modal.Footer>
      )}
    </Modal>
  );
}
ModalBlock.propTypes = {
  modalSize: PropTypes.oneOf(["sm", "lg", "xl"]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool,
  centered: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  dialogClassName: PropTypes.string,
  dialogBodyClassName: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  headerTitleSubText: PropTypes.string,
  headerSubtitle: PropTypes.string.isRequired,
  buttons: PropTypes.shape({
    headerButtons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        classes: PropTypes.arrayOf(PropTypes.string).isRequired,
        isPrimary: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.node,
      })
    ).isRequired,
    footerButtons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        classes: PropTypes.arrayOf(PropTypes.string).isRequired,
        isPrimary: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.node,
      })
    ).isRequired,
  }),
  children: PropTypes.node.isRequired,
  isFooter: PropTypes.bool,
};
ModalBlock.defaultProps = {
  fullscreen: false,
  centered: false,
  dialogClassName: "",
  dialogBodyClassName: "",
  headerTitleSubText: "",
  buttons: {},
  isFooter: false,
};
export default ModalBlock;
