import PropTypes from "prop-types"; // Import PropTypes
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./StyleTabLink.module.scss";
import { LOCATION } from "../../../../Constants";

function TabItem({
  name, link, isActive, onClick,
}) {
  return (
    <li
      className={`list-inline-item   ${isActive ? styles.active_List : ""}`}
      onClick={() => onClick()}
    >
      {isActive || !link ? (
        <span
          className={`${
            !link ? (isActive ? "cursor-default" : "cursor-pointer") : ""
          }`}
        >
          {name}
        </span>
      ) : (
        <NavLink to={link}>
          {name}
        </NavLink>
      )}
    </li>
  );
}

/**
 * TabLink component for rendering a list of tabs.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.tabList - The list of tab objects.
 * @param {string} props.currentPath - The current URL path.
 */

function TabLink({
  tabList,
  currentPath,
  onClick,
  borderBottom = false,
}) {
  return (
    <div
      className={`${styles.tab_container} ${
        borderBottom && styles.tab_container_extra
      }`}
    >
      <ul className="list-inline mb-0">
        {tabList?.map((item) => {
          if (item.hide) {
            return <span />;
          }
          return (
            <TabItem
              key={item.key}
              name={item.name}
              link={item.link}
              isActive={item.link === currentPath || (currentPath.includes(LOCATION) && item.key === "locations")}
              onClick={item.link ? onClick : item.onClick}
            />
          );
        })}
      </ul>
    </div>
  );
}

TabLink.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPath: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TabLink.defaultProps = {
  onClick: () => {
  },
};

export default TabLink;
