import moment from "moment";
import { Cookies } from "react-cookie";
import {
  API_BASE_URL,
  CUSTOMER_JOURNEY_LIST,
  DRIVE_LIST,
  HOME, ROOTURL,
  TASK_DETAILS_LINK,
} from "../Constants";
import { limitOptions } from "../Constants/limitOptions";
import { store } from "../Store";
import {
  addAccessListAction,
  addRedirectUrlAfterLoginAction,
  addUserDataToStoreAction,
} from "../Store/actions";
import { TOGGLE_LOADER } from "../Store/constants";
import LoadSegmentScript from "./LoadSegmentScript";
import callApi from "./callApi";
import { showAlert } from "./showAlert";

const hideCameraFor = ["OpenEye", "Goodwill Northern Illinois"];

const checkHost = [
  "app.wobot.ai",
  "dev.wobot.ai",
  "staging.wobot.ai",
  "localhost:3000",
];

const demoAccountCredentials = {
  username: "demo@wobot.ai",
  password: "Demo@123",
};

const handleLoader = (state, label = "") => {
  store.dispatch({
    type: TOGGLE_LOADER,
    value: {
      label,
      value: state,
    },
  });
};
export const checkPersonSuite = (id) =>
  // MCD comapny id to enable walk-in
  // || id === "6474956f7d292569eb525329  (Staging ID help-test-edge2)"
  id === "64535dab2f6be3b461f7cd28";
export const hideCameraManufacturer = (...names) =>
  names.some((name) => hideCameraFor.includes(name));

export const checkCompanyTaskDetails = (company = "", task = "") =>
  company === "643edf662cda004e4fc507e9" && task === "64414fdffdf9f62e5c52ff09";
export const loginService = (loginResponse, config) => {
  const { userData } = store.getState();

  if (!loginResponse?.hideLoader) {
    handleLoader(true, "");
  }
  store.dispatch(addUserDataToStoreAction(loginResponse.data));
  store.dispatch(addAccessListAction(loginResponse.data.accessList));
};
export const sliceLongName = (name) =>
  name.length < 25 ? name : `${name.slice(0, 25)}...`;

export function SlicedText({ title, maxLength }) {
  const slicedTitle =
    title.length <= maxLength ? title : `${title.slice(0, maxLength)}...`;

  return <span>{slicedTitle}</span>;
}

export const sliceTaskName = (title) =>
  title.length < 34 ? title : `${title.slice(0, 34)}...`;

export const sliceTaskSetupName = (title) =>
  title.length < 24 ? title : `${title.slice(0, 24)}...`;

export const sliceCameraTaskName = (title) =>
  title.length < 28 ? title : `${title.slice(0, 28)}...`;

export const sliceLocationName = (title) =>
  title.length < 38 ? title : `${title.slice(0, 38)}...`;

export const sliceRegionName = (title) =>
  title.length < 35 ? title : `${title.slice(0, 35)}...`;

export const sliceText = (text, length = 24) => {
  return text.length < length ? text : `${text.slice(0, length)}...`;
};

// Get Location, region, city count using region type (manifest)
export const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
export const getLimitInRange = (limitQuery) => {
  if (!limitOptions.includes(limitQuery)) {
    return limitOptions?.reduce((closestLimit, limit) =>
      Math.abs(limit - limitQuery) < Math.abs(closestLimit - limitQuery)
        ? limit
        : closestLimit
    );
  }
  return limitQuery;
};

export const formatTimeTo12Hour = (time) =>
  moment(time, "HH:mm").format("h:mm A");
class Service {
  static checkHost = (key) => {
    if (typeof key !== "string" || key === undefined) {
      throw new Error("key must be a string");
    }
    return window?.location?.host?.includes(key);
  };

  static getNumberWithCommas = (x) =>
    x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  static isOnline() {
    return navigator.onLine;
  }

  static makeString(num, label) {
    return num > 1 ? `${num} ${label}s` : `${num} ${label}`;
  }

  // config = {isDemoAccount : bool , username : "" , password : "" , history : func  }
  static async login(config) {
    try {
      // creating payload for login
      const apiPayload = {
        username: config.isDemoAccount
          ? demoAccountCredentials.username
          : config.username,
        password: config.isDemoAccount
          ? demoAccountCredentials.password
          : config.password,
      };

      const loginResponse = await callApi(
        `${ROOTURL}partner/v1/login`,
        {
          method: "POST",
          body: JSON.stringify(apiPayload),
        },
        { showLoader: true, callManifest: false, loaderLabel: "" }
      );
      if (loginResponse.status === 200) {
        if (config?.next) {
          store.dispatch(addRedirectUrlAfterLoginAction(config?.next));
        }
        loginService(loginResponse, config);
      }
    } catch (e) {
      showAlert(e, "error");
    }
  }
}
export const getRedirectLink = (
  taskId,
  yesterdayDateAndTime,
  todayDateAndTime,
  slug,
  location = ""
) => {
  if (slug === "drivethru-journey-tracking") {
    return DRIVE_LIST;
  }
  if (slug === "customer-journey-tracking") {
    return CUSTOMER_JOURNEY_LIST;
  }
  return `${TASK_DETAILS_LINK}/${taskId}?${
    location ? `location=${location}&` : ""
  }from=${yesterdayDateAndTime}&to=${todayDateAndTime}&range=Custom`;
};

export const shouldShowFooter = () =>
  !checkHost.includes(window?.location?.host);

export default Service;
