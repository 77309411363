import "./HelpSection.scss";

import {
  IconWhatNew,
  IconKnowledge,
  IconSiteStatus,
  IconRaiseTicket,
  IconSupportChat,
} from "../../../../../Common/Components/IconsComponent/Index";
import { useState } from "react";
import { store } from "../../../../../Store";

const HelpSection = (props) => {
  const [iconWhatNewColor, setIconWhatNewColor] = useState("#545454");
  const [iconKnowledgeColor, setIconKnowledgeColor] = useState("#545454");
  const [iconSiteStatusColor, setIconSiteStatusColor] = useState("#545454");
  const [iconRaiseTicketColor, setIconRaiseTicketColor] = useState("#545454");
  const [iconSupportChatColor, setIconSupportChatColor] = useState("#545454");
  const { userData } = store.getState();

  // IconWhatNew mouse enter
  const iconWhatNewEnter = () => {
    setIconWhatNewColor("#194DDE");
  };
  // IconWhatNew mouse leave
  const iconWhatNewLeave = () => {
    setIconWhatNewColor("#545454");
  };
  // IconKnowledge enter
  const iconKnowledgeEnter = () => {
    setIconKnowledgeColor("#194DDE");
  };
  // IconKnowledge leave
  const iconKnowledgeLeave = () => {
    setIconKnowledgeColor("#545454");
  };
  // IconSiteStatus enter
  const iconSiteStatusEnter = () => {
    setIconSiteStatusColor("#194DDE");
  };
  // IconSiteStatus leave
  const iconSiteStatusLeave = () => {
    setIconSiteStatusColor("#545454");
  };

  // IconRaiseTicket enter
  const iconRaiseTicketEnter = () => {
    setIconRaiseTicketColor("#194DDE");
  };

  // IconRaiseTicket leave
  const iconRaiseTicketLeave = () => {
    setIconRaiseTicketColor("#545454");
  };

  // IconSupportChatColor enter
  const iconSupportTicketEnter = () => {
    setIconSupportChatColor("#194DDE");
  };

  // IconSupportChatColor leave
  const iconSupportTicketLeave = () => {
    setIconSupportChatColor("#545454");
  };

  return (
    <div className="help-section">
      <ul className="mb-0">
        <li className="help-section__link">
          <span>
            <IconWhatNew color={iconWhatNewColor} />
          </span>
          <a
            href="https://updates.wobot.ai/updates"
            target="_blank"
            onMouseEnter={iconWhatNewEnter}
            onMouseLeave={iconWhatNewLeave}
          >
            What’s new
          </a>
        </li>
        <li className="help-section__link">
          <span>
            <IconKnowledge color={iconKnowledgeColor} />
          </span>
          <a
            href="https://help.wobot.ai/"
            target="_blank"
            onMouseEnter={iconKnowledgeEnter}
            onMouseLeave={iconKnowledgeLeave}
          >
            Knowledgebase
          </a>
        </li>
        <li className="help-section__link">
          <span>
            <IconSiteStatus color={iconSiteStatusColor} />
          </span>
          <a
            href="https://status.wobot.ai/"
            target="_blank"
            onMouseEnter={iconSiteStatusEnter}
            onMouseLeave={iconSiteStatusLeave}
          >
            Site status
          </a>
        </li>
        <div className="help-section__line"></div>
        <li className="help-section__link">
          <span>
            <IconRaiseTicket color={iconRaiseTicketColor} />
          </span>
          <a
            href="https://help.wobot.ai/en/"
            target="_blank"
            onMouseEnter={iconRaiseTicketEnter}
            onMouseLeave={iconRaiseTicketLeave}
          >
            Raise a support ticket
          </a>
        </li>
        {userData?.user?.role === "Owner" &&
        process.env.REACT_APP_ENVIRONMENT === "app" ? (
          <li className="help-section__link">
            <button
              className="btn p-0 help-btn-link"
              onMouseEnter={iconSupportTicketEnter}
              onMouseLeave={iconSupportTicketLeave}
              onClick={() => {
                if (
                  userData?.user?.role === "Owner" &&
                  process.env.REACT_APP_ENVIRONMENT === "app"
                ) {
                  if (window?.HubSpotConversations) {
                    window.HubSpotConversations.widget.open();
                    document
                      .querySelector("#hubspot-messages-iframe-container")
                      .style.setProperty("display", "initial", "important");
                  }
                }
              }}
            >
              <span className="mr-2">
                <IconSupportChat color={iconSupportChatColor} />
              </span>
              Support chat
            </button>
          </li>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
};

export default HelpSection;
