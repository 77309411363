import { getRangeColorsByHost } from "../Utils/getRangeColorsByHost";

export const colourStyles = {
  control: (base, { isDisabled, isSelected, isFocused }) => ({
    ...base,
    borderColor: isFocused
      ? `${getRangeColorsByHost()} !important`
      : isSelected
      ? "#CED4DA !important"
      : isDisabled
      ? "#CED4DA !important"
      : "#CED4DA !important",
    borderWidth: "1px !important",
    borderStyle: "solid !important",
    transition: "unset !important",
    boxShadow: isFocused
      ? `0 0 0 1px ${getRangeColorsByHost()} !important`
      : "unset !important",
    "&:hover": {
      border: `1px solid ${getRangeColorsByHost()} !important`,
    },
    borderRadius: "6px !important",
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    cursor: isDisabled ? "auto" : "pointer",
    // color: isSelected ? "#fff" : "#7E7E7E",
    fontSize: isDisabled ? "10px" : isDisabled,
    fontWeight: isDisabled ? "500" : isDisabled,
    textTransform: isDisabled ? "uppercase" : isDisabled,
    letterSpacing: isDisabled ? "1.5px" : isDisabled,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? getRangeColorsByHost()
      : isFocused
      ? "#EBECF0"
      : undefined,
    color: isDisabled
      ? undefined
      : isSelected
      ? "#fff"
      : isFocused
      ? "#7E7E7E"
      : undefined,
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  menuList: (styles) => ({ ...styles, maxHeight: 250 }),
  menuPortal: (base) => ({ ...base, zIndex: 99 }),
  menu: (styles) => ({
    ...styles,
    overflowX: "hidden",
    hyphens: "manual",
    wordWrap: "break-word",
  }),
};
