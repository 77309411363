import React, {memo, useEffect, useState} from "react";
import ReactSelect from "react-select";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import DataTable from "react-data-table-component";
import styles from "./TaskLogStyle.module.scss";
import ModalBlock from "../../../Common/Components/Molecule/ModalBlock";
import ZeroStateInfiniteScroll from "../../../Common/Components/Molecule/ZeroStateInfiniteScroll";
import fetchTaskLog from "../Requests/fetchTaskLog";
import mapTaskLog from "../Requests";
import {colourStyles} from "../../../Services/colourStyles";
import Tag from "../../../Common/Components/Molecule/Tag";

function CameraLog({handleShowLog, showLog, camera}) {
    const [loading, setLoading] = useState(false);
    const [selectedCamera, setSelectedCamera] = useState();
    const [taskLogs, setTaskLogs] = useState([]);

    const init = async (val) => {
        setLoading(true);
        const {data} = await fetchTaskLog({camera: val});
        setLoading(false);
        const arr = [...data?.cloudOfflineAlertLogs?.alertLog] || [];
        if (data?.edgeOfflineAlertLogs?.alertLog) {
            arr.concat([...data?.edgeOfflineAlertLogs?.alertLog]);
        }
        setTaskLogs(() => {
            return arr;
        });
    };

    useEffect(() => {
        if (camera && camera.length) {
            setSelectedCamera([camera[0]]);
            init(camera[0].value);
        }
    }, [showLog]);

    return (
        <ModalBlock
            headerTitle="Camera Logs"
            headerSubtitle="Latest camera logs"
            isOpen={showLog}
            modalSize="lg"
            fullscreen
            onHide={() => handleShowLog()}
            dialogBodyClassName={styles.tasklogsModal}
            buttons={{
                headerButtons: [],
                footerButtons: [],
            }}
            dialogClassName={'minHeight'}
        >
            <div id="table-content">
                <div className="col-4 offset-8">
                    <ReactSelect
                        inputId="camera"
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        styles={colourStyles}
                        className="react-select view-select"
                        value={selectedCamera}
                        onChange={(e) => {
                            setSelectedCamera(e);
                            init(e.value);
                        }}
                        options={camera}
                    />
                </div>
                {loading ? (
                    <Skeleton count={20} height={56} className="mb-2"/>
                ) : (
                    <InfiniteScroll
                        dataLength={taskLogs ? taskLogs?.length : 0}
                        next={() => {
                            // setPage(page + 1);
                            // init(selectedCamera.value);
                        }}
                        hasMore={false}
                        scrollThreshold={0.5}
                        height="100%"
                        className={styles.infiniteScroll}
                        loader={<Skeleton count={20} height={56} className="mb-2"/>}
                        endMessage={taskLogs?.length > 0 && <ZeroStateInfiniteScroll/>}
                    >
                        <DataTable
                            className="mt-2"
                            columns={[{
                                name: "log",
                                selector: (row) => row.log,
                                minWidth: "200px",
                            }, {
                                name: "Time",
                                selector: (row) => row.created_at,
                                maxWidth: "200px",
                            }, {
                                name: "status",
                                selector: (row) => (
                                    <Tag
                                        className={(row.status) === "Online" ? "tag-success" : "tag-orange"}
                                    >
                                        {row.status}
                                    </Tag>
                                ),
                                maxWidth: "200px",
                            }]}
                            data={mapTaskLog(taskLogs)}
                            pagination={false}
                        />
                    </InfiniteScroll>
                )}
            </div>
        </ModalBlock>
    );
}

export default memo(CameraLog);
