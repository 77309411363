import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { API_BASE_URL, VERIFY } from "../../../Constants";
import callApi from "../../../Services/callApi";
import {
  PAGE_TITLES,
  checkWobotDomain,
} from "../../../Services/checkWobotDomain";
import { showAlert } from "../../../Services/showAlert";
import { validateEmail } from "../../../Services/validation";
import CenterBlock from "../Molecule/Block/CenterBlock";
import RotateBlock from "../Molecule/RotateBlock";
import "../SignupComponent/style.scss";

const ForgotPasswordComponent = (props) => {
  const location = useLocation();
  const { email = "" } = location?.state || {};
  const history = useHistory();
  const [username, setUsername] = useState(email);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleSubmit = async () => {
    setIsEmailValid(validateEmail(username));
    if (!isEmailValid && username) {
      // if (username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      try {
        let forgotPasswordResponse = await callApi(
          `${API_BASE_URL}/forgot-password`,
          {
            method: "POST",
            body: JSON.stringify({
              username,
            }),
          }
        );
        if (forgotPasswordResponse.status === 200) {
          history.push(`${VERIFY}`, {
            user: {
              email: forgotPasswordResponse.data.email,
              id: forgotPasswordResponse.data.id,
            },
          });
        }
        showAlert(forgotPasswordResponse.message);
      } catch (e) {
        showAlert(e, "error");
      }
      // }
    }
  };

  return (
    <RotateBlock showAuthBoxFw={false} showAuthBox={true}>
      <Helmet>
        <title>{checkWobotDomain(PAGE_TITLES.forgotPassword)}</title>
      </Helmet>
      <CenterBlock
        showWobotIcon={true}
        showAuthBottomLink={false}
        showAuthBottomNav={true}
        className={"auth-login-layout auth-login"}
      >
        <div className={"auth-content"}>
          <h3 className={"auth-title"}>Forgot Password?</h3>
          <p className={"mb-4"}>
            Please enter your email and we'll send you a link to reset your
            password.
          </p>
          <div className="login-form">
            <div className={"auth-form-block"}>
              <label htmlFor="inputAddress" className="form-label">
                Email address
              </label>
              <input
                type="text"
                tabIndex={1}
                value={username}
                name="username"
                className={`form-control ${isEmailValid ? "error-red" : ""}`}
                placeholder="e.g. john@example.com"
                onChange={(e) => {
                  let value = e.target.value.trim();
                  setUsername(value);
                  setIsEmailValid(validateEmail(value));
                }}
              />
              {isEmailValid && (
                <span className="error-msg">Please enter a valid email</span>
              )}
            </div>
            <button
              className="btn btn-block btn-primary btn-xl mt-5"
              tabIndex={2}
              onClick={handleSubmit}
            >
              Send Email
            </button>
            <Link to="/login" className="d-block mt-3">
              Return to Log In
            </Link>
          </div>
        </div>
      </CenterBlock>
    </RotateBlock>
  );
};

export default ForgotPasswordComponent;
