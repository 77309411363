import React from "react";
/**
 * Common Routes For External-Dashboard
 * */
// Sidebar Icons Import
import {IconTask} from "../Common/Components/IconsComponent/Index";
import {IconSettings} from "../Common/Components/IconsComponent/SidebarMain/Index";

/**
 * Common Routes For External-Dashboard
 * */
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const APP_KEY = process.env.REACT_APP_KEY;
export const AUTHORIZATION = process.env.REACT_APP_AUTHORIZATION;
export const TOKEN = process.env.REACT_APP_TOKEN;
export const ROOTURL = process.env.REACT_APP_API;

export const API_BASE_URL_PUBLIC = process.env.REACT_APP_API_BASE_URL_PUBLIC;
export const DEFAULT_API_CONFIG = {
    showLoader: false,
    callManifest: true,
    loaderLabel: "",
};

export const APP_SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const API_CONFIG_NO_MANIFEST_CALL = {
    showLoader: false,
    callManifest: false,
    loaderLabel: "",
};

export const API_CONFIG_SHOWLOADER = {
    showLoader: true,
    callManifest: false,
    loaderLabel: "Wait for sometime to complete the selected action",
};

// in milliseconds
export const DEBOUNCE_INPUT_TIME = 500;

/**
 * Routes For External-Dashboard
 * */

export const LOGIN = "/login";
export const SSOLOGIN = "/login/sso";

export const SIGNUP = "/signup";

export const FORGET = "/forgot";

export const VERIFY = "/verify";

export const CHANGEPASSWORD = "/change-password";
export const SETUPPASSWORD = "/setup-password";
export const SUCCESS = "/success";

export const ADDCOMPANY = "/add-company";

export const ADDTEAM = "/add-team";

export const ADDLOCATION = "/add-location";

export const CONNECTCAMERA = "/connect-camera";

export const DRIVETHRU = "/drive-thru";
export const MODELS = "/models";
export const LOCATION = "/models/location";
export const LOCATION_DETAILS = "/models/location/:locationId";

export const VIEW = "/wocam/live";
export const CREATE_VIEW = "/wocam/live/create";
export const PLAYBACK = "/wocam/playback";
export const RECORDER = "/wocam/nvrs";
export const CAMERA = "/wocam/cameras";
export const CAMERA_DETAIL = "/wocam/cameras/details/:cameraId";
export const CAMERA_DETAIL_ID = "/wocam/cameras/details/";
export const CAMERA_DETAIL_OLD = "/wocam/camera/detail/:cameraId";
export const RECORDER_DETAIL = "/wocam/nvrs/details/:recorderId";

export const REGION = "/settings/regions";
export const EMPLOYEE_PROFILE = "/profile-details";
export const COMPANY_DETAILS = "/settings/company-details";
export const SUBREGION = "/settings/cities";
// export const LOCATION = "/settings/locations";
/* export const LOCATION_DETAILS =
  "/settings/locations/location-details/:locationId"; */
export const WOCONNECT_SETTINGS = "/settings/locations/location-details/:locationId/:deviceId";
export const TAT = "/settings/tat";
export const SCHEDULE = "/settings/schedules";
export const SHIFT = "/settings/shifts";
export const STATION = "/settings/station-types";
export const ZONE = "/settings/zone-types";
export const EMPLOYEE = "/settings/users";
export const ADDEMPLOYEE = "/settings/users/add";
export const EMPLOYEE_DETAIL = "/settings/users/detail/:employeeId";
export const SUBSCRIPTION_DETAILS = "/settings/plan";
export const ADVANCED_SETTINGS = "/settings/advance";
export const EMAIL_VERIFY = "/company/verification/:accessId";
export const CAMERA_INTEGRATION = "/settings/integration";
export const OPENEYE = "/settings/integration/openeye";
export const JOLT = "/settings/integrations/jolt";
// export const TASK = "/task/list";
// export const TASK_DETAILS = "/task/list/detail";
export const TASK = "/task/schedule-task";
export const TASK_DETAILS = "/task/schedule-task/detail";
export const SSO = "/settings/integration/sso";

export const CHECKLIST = "/tasks";
export const CHECKLIST_DETAIL = "/task/checklist/:id";
export const CHECKLIST_INTRODUCTION = "/task/checklist/introduction";
export const MY_TASK = "/task/my-tasks-list";

export const TASK_TYPE = "/tasks/details/:id";
export const TASK_DETAILS_LINK = "/tasks/details";
export const TASK_TYPE_EVENTS = "/tasks/details/:id/events";

export const EXECUTIVE_TASKS = "/task/my-task";
export const EXECUTIVE_TASKS_DETAILS = "/task/executive/details";

export const TICKETING = "/tickets";
export const TICKETING_DETAILS = "";

export const COMPLIANCE_NAVBAR = "/events";

export const COMPLIANCE_DETAILS = "/events/details";

export const TASK_ALL_DETAILS = "/task/checklist/:taskId";

export const COMPLIANCE_RUN = "/tasks/run/";
export const COMPLIANCE_CONFIG = "/tasks/run/:checklistId/:taskId";
export const COMPLIANCE = "/task/run/:checklistId";
export const CONFIGURATION = "/task/checklist/compliance/configuration";
export const ADD_CAMERA = "/task/checklist/compliance/add-camera";
export const ADD_SCHEDULE = "/task/checklist/compliance/add-schedule";
export const ASSIGN_USER = "/task/checklist/compliance/assign-user";

export const HOME = "/home";
export const LOGOUT = "/logout";
export const PAGE404 = "/404";
export const PAGE500 = "/500";

// export const ATTENDANCE_LIST = "/attendance";

export const ANALYTICS_TICKETS = "/analytics/for-tickets";
export const ANALYTICS_TASKS = "/analytics/for-tickets/view-specific";

export const ANALYTICS_EVENTS = "/analytics/for-events";
export const ANALYTICS_EVENTS_TASKS = "/analytics/for-events/view-specific";

export const MOBILEAPPDOWNLOAD = "/app/download";
// Drivethru
export const DRIVE_LIST = "/drivethru";
export const DRIVE_LOCATION_DETAILS = "/drivethru/location-detail/:locationId";
export const DRIVE_OPERATIONAL_DASHBOARD = "/drivethru/operations/:locationId";
export const DRIVETHRU_CAR_JOURNEYS = "/drivethru/car-journeys";
export const DRIVETHRU_VECHICLES = "/drivethru/cars/journey";
export const DRIVETHRU_DETAILS = "/drivethru/cars/journey/:journeyId/:licencePlate";
// Customer Journey Tracking
export const CUSTOMER_JOURNEY_LIST = "/customer-journey";
export const CUSTOMER_JOURNEY_LOCATION_DETAILS = "/customer-journey/location-detail/:locationId";
export const CUSTOMER_OPERATIONAL_DASHBOARD = "/customer-journey/operations/:locationId";
export const CUSTOMER_JOURNEYS = "/customer-journey/journeys";
export const CUSTOMER_JOURNEY = "/customer-journey/customer/journey";
export const CUSTOMER_JOURNEY_DETAILS = "/customer-journey/journey/:journeyId/:licencePlate";
//----------------------------------------------------------------
export const CUSTOMER_ANALYTICS = "/analytics/for-customer";
export const PERSON_SUITE = "/person-suite";
export const PERSON_SUITE_DETAIL = "/person-suite/detail";
export const WALK_IN = "/walk-in";

// Links
export const PLACEHOLDER_IMAGE = "https://wobot-prod-application.s3.ap-south-1.amazonaws.com/public/placeholder.png";

// dashboard demo link
export const DEMO_URL = "https://demo.wobot.ai";
// checklist info titles
export const CHECKLIST_TITLES = {
    overview: "Overview",
    process: "Process",
    dos: "Do's And Don'ts",
    technicalDetails: "Technical Details",
    expectedResults: "Expected Results",
    additionalInfo: "Additional info",
};
export const CHECKLIST_TAB = [
    "Overview",
    "Process",
    "Do's And Don'ts",
    "Technical Details",
    "Expected Results",
    "Additional info",
];

// Task types

export const TASK_TYPES = {
    COUNT_BASED: "count-based",
    TIME_BASED: "time-based",
    OCCUPANCY_BASED: "occupancy-based",
    PROCESS_BASED: "process-based",
    WOPIPE: "wopipe",
    JOURNEY_BASED: "journey-based",
    HEATMAP_BASED: "heatmap-based",
    PROCESS_COMPLIANCE: "process-compliance",
};
export const configDeploymentTypeOptionTitles = {
    DEPLOYMENT_MODE: "Deployment mode",
    OPERATIONAL_DASHBOARD_DISPLAY_ORDER: "Operational dashboard display order",
};
/**
 * Routes For Sidebar Collapse
 * */

const routes_hover = [
    // Home
    /* {
        icon: <IconHome />,
        title: "Home",
        name: "home",
        link: "/home",
        subMenu: [],
      }, */
    // Drive-thru
    {
        icon: <IconTask/>,
        title: "Task",
        name: "drive",
        link: DRIVETHRU,
        subMenu: [],
    },
    // Drive-thru
    {
        icon: <IconSettings/>,
        title: "Models",
        name: "models",
        link: MODELS,
        subMenu: [],
    },
];

/**
 * Routes For Sidebar
 * */

const routes = [
    {
        enabled: true,
        path: VIEW,
        navbar: "Live",
        iconName: "IconLive",
        child: null,
    },
    {
        enabled: true,
        path: PLAYBACK,
        navbar: "Playback",
        iconName: "IconPlayback",
        child: null,
    },
    {
        enabled: true,
        path: RECORDER,
        navbar: "DVR/NVR",
        iconName: "IconDVR",
        child: null,
    },
    {
        enabled: true,
        path: CAMERA,
        navbar: "Camera",
        iconName: "IconCamera",
        child: null,
    },
];

const routes_2 = [
    {
        enabled: true,
        path: EMPLOYEE,
        navbar: "Employees",
        iconName: "IconEmployee",
        child: null,
    },
    {
        enabled: true,
        path: REGION,
        navbar: "Regions",
        iconName: "IconGlobe",
        child: null,
    },
    {
        enabled: true,
        path: SUBREGION,
        navbar: "Cities",
        iconName: "IconMap",
        child: null,
    },
    {
        enabled: true,
        path: LOCATION,
        navbar: "Branches",
        iconName: "IconLocation",
        child: null,
    },
    {
        enabled: true,
        path: SCHEDULE,
        navbar: "Schedule",
        iconName: "IconCalender",
        child: null,
    },
    {
        enabled: true,
        path: SUBSCRIPTION_DETAILS,
        navbar: "Subscription",
        iconName: "IconCalender",
        child: null,
    },
    /* {
              enabled: true,
              path: COMPANY_DETAILS,
              navbar: "Company Details",
              iconName: "IconEmployee",
              child: null,
          }, */
];

const routes_3 = [
    {
        enabled: true,
        path: CHECKLIST,
        navbar: "Checklist",
        iconName: "IconCheckbox",
        child: null,
    },
    {
        enabled: true,
        path: TASK,
        navbar: "Schedule Task",
        iconName: "IconScheduleTask",
        child: null,
    },
];

const routes_4 = [
    {
        enabled: true,
        path: HOME,
        navbar: "Home",
        iconName: "IconHome",
        child: null,
    },
];

const routes_5 = [
    {
        enabled: true,
        path: TICKETING,
        navbar: "Ticketing",
        iconName: "IconTicketing",
        child: null,
    },
    {
        enabled: false,
        path: COMPLIANCE_NAVBAR,
        navbar: "Compliance Tracking",
        iconName: "IconEvent",
        child: null,
    },
];

export {
    routes, routes_2, routes_3, routes_4, routes_5, routes_hover,
};
