import React from "react";

const IconTask = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.49471V13.5054C11.9986 13.6805 11.9284 13.848 11.8045 13.9717C11.6807 14.0954 11.5131 14.1655 11.338 14.1667H0.662C0.486542 14.1667 0.318261 14.0971 0.194131 13.973C0.070001 13.849 0.000176695 13.6808 0 13.5054V1.49471C0.00139643 1.31963 0.071612 1.15212 0.195478 1.02838C0.319345 0.904641 0.486919 0.834594 0.662 0.833374H11.338C11.7033 0.833374 12 1.12937 12 1.49471ZM10.6667 2.16671H1.33333V12.8334H10.6667V2.16671ZM5.52867 8.24737L8.35733 5.41937L9.3 6.36204L5.52867 10.1334L2.93533 7.54004L3.87867 6.59737L5.52867 8.24737Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconTask;
