import React from "react";

const ZeroStateInfiniteScroll = ({ title }) => {
  return (
    <div className={"col-12 mt-5 w-100"}>
      <div className={"text-center"}>
        <span className={"fs-14 text-other"}>
          {title || "End of list reached"}
        </span>
      </div>
    </div>
  );
};

export default ZeroStateInfiniteScroll;
