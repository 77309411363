import React from "react";
import {PAGE_TITLES} from "../../Services/checkWobotDomain";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";
import DriveThruComponent from "../Components";

const DriveThru = () => {
    return (
        <PageContainer
            initialPageTitle={PAGE_TITLES.station}
            meta={""}
        >
            <DriveThruComponent/>
        </PageContainer>
    );
};

export default DriveThru;
